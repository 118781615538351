import PatientFull from 'models/PatientInfo';
import React, { useContext, useEffect, useState } from 'react';
import './LegacyReportGenerator.css';
import { GlobalContext } from '../../store/global-context';
import { Spinner, ThemeProvider } from '@ddx/component-library';
import { toast, ToastContainer } from 'react-toastify';

const LegacyReportGenerator = () => {
  const globalCtx = useContext(GlobalContext);
  const [isClintLookup, setIsClintLookup] = useState(false);
  const toggleClintLookupV2 = React.useCallback(
    () => setIsClintLookup(!isClintLookup),
    [isClintLookup, setIsClintLookup]
  );

  useEffect(() => {
    globalCtx.reportGeneratorHealthCheck();
  });

  const handleFormSubmitManual = async (event: any) => {
    event.preventDefault();
    const patientInfo: PatientFull = {
      firstName: event.target[1].value,
      lastName: event.target[2].value,
      birthDate: event.target[5].value,
      mrn: event.target[3].value,
      subId: event.target[4].value,
      analysisDateTime: event.target[6].value,
      imagingDate: event.target[7].value,
      analysisResult: event.target[8].value,
    };
    //Legacy Report
    let isLegacy: string = event.target[9].value;

    //images
    let bodyFormData = new FormData();
    bodyFormData.append('patient', JSON.stringify(patientInfo));

    try {
      bodyFormData.append('leftDisk', event.target[10].files[0]);
      bodyFormData.append('leftMacula', event.target[11].files[0]);
      bodyFormData.append('rightDisk', event.target[12].files[0]);
      bodyFormData.append('rightMacula', event.target[13].files[0]);
    } catch (err) {
      console.log(err);
    }
    bodyFormData.append('isLegacy', isLegacy);

    globalCtx.setIsCreateReportLoading(true);
    const response = await globalCtx.createTestReport(bodyFormData);

    if (response !== null) {
      toast.success('Report created successfully.');

      var a = document.createElement('a'); //Create <a>
      a.href = 'data:application/pdf;base64,' + response; //Image Base64 Goes here
      a.download = `${patientInfo.subId}.pdf`; //File name Here
      a.click(); //Downloaded file
    } else {
      toast.error('An error occurred while generating the report.');
    }
  };

  const handleFormSubmitClint = async (event: any) => {
    event.preventDefault();
    let bodyFormData = new FormData();
    const orderId = event.target[1].value;
    const isLegacy = event.target[2].value;
    const images = event.target[3].files;

    bodyFormData.append('orderId', orderId);
    for (let i = 0; i < images.length; i++) {
      bodyFormData.append('images', images[i]);
    }
    bodyFormData.append('isLegacy', isLegacy);
    console.log(orderId, images);

    globalCtx.setIsCreateReportLoading(true);
    const response = await globalCtx.createClintReport(bodyFormData);

    if (response !== null) {
      toast.success('Report created successfully.');

      //download pdf here
      var a = document.createElement('a'); //Create <a>
      a.href = 'data:application/pdf;base64,' + response; //Image Base64 Goes here
      a.download = `${orderId}.pdf`; //change this, send base64 + subId perhaps
      a.click(); //Downloaded file
    } else {
      toast.error('An error occurred while generating the report.');
    }
  };

  if (globalCtx.isCreateReportLoading) {
    return (
      <div className="ReportGenerator">
        <ThemeProvider>
          <div className="spinner">
            <Spinner size="large" />
          </div>
        </ThemeProvider>
      </div>
    );
  }

  if (!isClintLookup) {
    return (
      <div className="ReportGenerator">
        <h1>Report Generator</h1>
        <form onSubmit={handleFormSubmitManual} className="patientForm">
          <div className="clintInputMode">
            <button type="button" onClick={toggleClintLookupV2}>
              {' '}
              Clint Order Lookup
            </button>
          </div>
          <div className="inputForm">
            <input
              name="firstName"
              id="firstName"
              placeholder="First Name"
              required
            />
            <input
              name="lastName"
              id="lastName"
              placeholder="Last Name"
              required
            />
            <input name="mrn" id="mrn" placeholder="MRN" required />
            <input
              name="subId"
              id="subId"
              placeholder="Submission ID"
              required
            />
            Birth Date
            <input type="date" name="birthDate" id="birthDate" required />
            Analysis Date Time
            <input
              type="datetime-local"
              name="analysisDateTime"
              id="analysisDateTime"
              required
            />
            imaging Date
            <input
              type="datetime-local"
              name="imagingDate"
              id="imagingDate"
              required
            />
            <label htmlFor="analysisResult">Analysis Result</label>
            <select id="analysisResult">
              <option value="Exam quality insufficient">Insufficient</option>
              <option value="No Diabetic Retinopathy Detected: ETDRS level 20 or lower and no Diabetic Macular Edema">
                Negative
              </option>
              <option value="Diabetic Retinopathy Detected: ETDRS level 35 or higher and/or Diabetic Macular Edema">
                Positive
              </option>
            </select>
            <label htmlFor="isLegacy">New or Legacy Report</label>
            <select id="isLegacy" required>
              <option value="false">New Report</option>
            </select>
            Left Disk
            <input
              type="file"
              id="leftDisk"
              name="leftDisk"
              accept="image/*"
              required
            />
            Left Macula
            <input
              type="file"
              id="leftMacula"
              name="leftMacula"
              accept="image/*"
              required
            />
            Right Disk
            <input
              type="file"
              id="rightDisk"
              name="rightDisk"
              accept="image/*"
              required
            />
            Right Macula
            <input
              type="file"
              id="rightMacula"
              name="rightMacula"
              accept="image/*"
              required
            />
          </div>

          <div className="patientSubmitButton">
            <button type="submit">Submit</button>
          </div>
        </form>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
  }
  return (
    <div className="ReportGenerator">
      <h1>Report Generator</h1>
      <form onSubmit={handleFormSubmitClint} className="patientForm">
        <div className="clintInputMode">
          <button type="button" onClick={toggleClintLookupV2}>
            {' '}
            Manual Order Entry
          </button>
        </div>
        <div className="clintInputForm">
          <input name="orderId" id="orderId" placeholder="Order Id" required />
          <label htmlFor="isLegacy">New or Legacy Report</label>
          <select id="isLegacy" required>
            <option value="true">Legacy Report</option>
            <option value="false">New Report</option>
          </select>
          <input
            type="file"
            id="images"
            name="images"
            accept="image/*"
            multiple
            required
          />
        </div>
        <div className="clintLookUp">
          <button type="submit">Submit</button>
        </div>
      </form>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default LegacyReportGenerator;
