import React, { useContext, useEffect } from 'react';
import { ServicesContext } from '../../store/services-context';

function Main({ children }) {
  const servicesCtx = useContext(ServicesContext);
  const auth = servicesCtx.services.auth;
  const queryParams = new URLSearchParams(window.location.search);
  const loginCode = queryParams.get('code');

  useEffect(() => {
    handleLogin();
  }, []);

  async function handleLogin() {
    const authToken = localStorage.getItem('access_token');
    const redirectUri = window.location.origin;

    if (!authToken && !loginCode) {
      auth.login(window.location.pathname);
    } else if (loginCode) {
      const newTokenData = await auth.newToken(loginCode, redirectUri);
      if (newTokenData !== null) {
        localStorage.setItem('id_token', newTokenData.id_token);
        localStorage.setItem('access_token', newTokenData.access_token);
        localStorage.setItem('refresh_token', newTokenData.refresh_token);
        localStorage.setItem('expires_in', newTokenData.expires_in);
      }
    }
  }

  // todo possibly add a react-router-dom <Redirect> using the state param in callback
  return <>{children}</>;
}

export default Main;
