import { AppConfig } from '../appConfig';
import axios from 'axios';

const createAuthService = (config: AppConfig) => {
  return {
    login: (path) => {
      login(path);
    },
    newToken: async (code, redirect_uri) => {
      const requestConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          grant_type: 'authorization_code',
          code,
          client_id: config.COGNITO_CLIENT_ID,
          redirect_uri,
        },
      };

      const postResponse = await axios.post(
        config.COGNITO_TOKEN_URL,
        null,
        requestConfig
      );

      return postResponse.data;
    },
    refreshToken: async (refresh_token, redirect_uri) => {
      const requestConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          grant_type: 'refresh_token',
          refresh_token,
          client_id: config.COGNITO_CLIENT_ID,
          redirect_uri,
        },
      };

      const postResponse = await axios.post(
        config.COGNITO_TOKEN_URL,
        null,
        requestConfig
      );

      return postResponse.data;
    },
    logout: (path) => {
      localStorage.removeItem('id_token');
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('expires_in');

      login(path);
    },
  };

  function login(path) {
    const loginUrlWithParams = `${config.COGNITO_LOGIN_URL}&redirect_uri=${config.APP_URL}&state=${path}`;
    window.location.replace(loginUrlWithParams);
  }
};

export type AuthService = ReturnType<typeof createAuthService>;
export default createAuthService;
