import createServices, { Services } from '../utils/services';
import { AppConfig } from '../utils/appConfig';
import React from 'react';

// Define context input props
export interface ServicesContextProviderProps {
  appConfig: AppConfig;
}

// Define context object
type ServicesContextObj = {
  services: Services;
};

// Default context object, provided in case ServicesContextProvider is not rendered
export const ServicesContext = React.createContext<ServicesContextObj>({
  services: null,
});

// Services context component
const ServicesContextProvider: React.FC<ServicesContextProviderProps> = ({
  children,
  appConfig,
}) => {
  const services = createServices(appConfig);
  const contextValue: ServicesContextObj = {
    services,
  };

  return (
    <ServicesContext.Provider value={contextValue}>
      {children}
    </ServicesContext.Provider>
  );
};

export default ServicesContextProvider;
