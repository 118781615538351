import Order from 'models/Order';
import Customer from 'models/Customer';
import { AppConfig } from '../appConfig';
import NewOrder from '../../models/NewOrder';
import { AuthService } from './auth';
import axios from 'axios';

const createOrderGeneratorService = (
  config: AppConfig,
  authService: AuthService
) => {
  const urlPrefix = '/api';
  const httpClient = axios.create({ baseURL: config.ORDER_GENERATOR_API_HOST });

  return {
    getCustomers: async () => {
      const token = localStorage.getItem('access_token');
      let response = null;

      if (token) {
        try {
          response = await httpClient.get<Customer[]>(
            `${urlPrefix}/customers`,
            {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            }
          );
          response = response.data;
        } catch (e) {
          if (e.response.status === 401) {
            authService.login(window.location.pathname);
          }
        }

        return response;
      }
    },
    postOrder: async (newOrder: NewOrder) => {
      const token = localStorage.getItem('access_token');
      let response = null;

      if (token) {
        try {
          response = await httpClient.post<Order>(
            `${urlPrefix}/orders`,
            {
              CustomerId: newOrder.customerId,
              Document: newOrder.document,
            },
            {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            }
          );
          response = response.data;
        } catch (e) {
          if (e.response.status === 401) {
            authService.login(window.location.pathname);
          }
        }

        return response;
      }
    },
  };
};

export type OrderGeneratorService = ReturnType<
  typeof createOrderGeneratorService
>;
export default createOrderGeneratorService;
