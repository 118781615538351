import GlobalContextProvider from './store/global-context';
import ServicesContextProvider from './store/services-context';
import appConfig from './utils/appConfig';
import { ThemeProvider } from '@ddx/component-library';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import './GlobalStyles.css';
import { LicenseInfo } from '@mui/x-license-pro';
import OrderGenerator from './components/orderGenerator/OrderGenerator';
import Navbar from './components/navbar/Navbar';
import LegacyReportGenerator from './components/legacyReportGenerator/LegacyReportGenerator';
import Authentication from './components/authentication/Authentication';

function AppRoot() {
  LicenseInfo.setLicenseKey(
    '44bd427489b76750644fe0481072d766Tz00Mzk1NCxFPTE2ODQ0NDE1MjQ2NTcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  );

  let defaultPath;
  const isOrderGeneratorEnabled =
    process.env.REACT_APP_ENABLE_ORDER_GENERATOR === 'true';

  if (isOrderGeneratorEnabled) {
    defaultPath = '/OrderGenerator';
  } else {
    defaultPath = '/LegacyReportGenerator';
  }

  return (
    <ServicesContextProvider appConfig={appConfig}>
      <GlobalContextProvider>
        <Authentication>
          <BrowserRouter>
            <ThemeProvider>
              <StyledEngineProvider injectFirst>
                <Navbar />
                <Route exact path="/">
                  <Redirect to={defaultPath} />
                </Route>
                {isOrderGeneratorEnabled && (
                  <Route
                    exact
                    path="/OrderGenerator"
                    component={OrderGenerator}
                  />
                )}
                <Route
                  exact
                  path="/LegacyReportGenerator"
                  component={LegacyReportGenerator}
                />
              </StyledEngineProvider>
            </ThemeProvider>
          </BrowserRouter>
        </Authentication>
      </GlobalContextProvider>
    </ServicesContextProvider>
  );
}

export default AppRoot;
