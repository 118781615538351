import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRoot from './AppRoot';
import { configureAuthentication, redirectIfUnauthenticated } from '@ddx/auth';

// configureAuthentication({
//   Auth: {
//     region: 'us-east-2',
//     userPoolId: 'us-east-2_1foYbz3yZ',
//     userPoolWebClientId: '38sbddkga06pfu5cjrjtj537b7',
//   },
//   oauth: {
//     domain: 'dev-idxdr-portal.auth.us-east-2.amazoncognito.com',
//     scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
//     redirectSignIn: 'http://localhost:3000',
//     redirectSignOut: 'http://localhost:3000',
//     responseType: 'code',
//   },
// });
// redirectIfUnauthenticated();

ReactDOM.render(<AppRoot />, document.getElementById('root'));
