import './OrderGenerator.css';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../store/global-context';
import { Spinner, ThemeProvider } from '@ddx/component-library';
import Customer from '../../models/Customer';
import NewOrder from '../../models/NewOrder';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Main() {
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>(null);
  const globalCtx = useContext(GlobalContext);

  useEffect(() => {
    globalCtx.setIsCustomersDataLoading(true);
    setTimeout(() => {
      globalCtx.fetchCustomersData();
    }, 500);
  }, []);

  const [enteredOrder, setEnteredOrder] = useState(
    'MSH|^~\\&|ECWSAM|TESTECWE|1100|BN|20220920114700||ORM|1659559682168474|P|2.2\n' +
      'PID|1|9132||9132|David1^David1||19450219|M||C|111 David Lane ^^Burlington^NC^27215|||||||11111173^^^Z\n' +
      'PV1|1||||||^Willis1^Sam1^^^^^U~1000000000^Willis^Sam^^^^^N\n' +
      'IN1|1|||Medicare|2 Technology Dr^^Westborough^MA^01581|||131313|||||||MC||1||||||||||||||N|||||123456789\n' +
      'GT1|1||TestLabCorp^Private||123 Testing Lane ^^Burlington^NC^27215||||||2\n' +
      'DG1|1|I1|I71.4^^I1\n' +
      'ORC|NW|170L28068||||||||||^Willis^Sam^^^^^U^^1000000000\n' +
      'OBR|1|170L28068||831153^IDx-DR Analysis Report^L|||202208031647||||N|||||||170L28091'
  );

  const orderChangeHandler = (event: any) => {
    setEnteredOrder(event.target.value);
  };

  const customers = globalCtx.customerData ?? [];

  const customerOptions = customers.map((customer) => {
    return (
      <option key={customer.name.toLowerCase()} value={customer.name}>
        {customer.name}
      </option>
    );
  });

  const handleSelectCustomer = (event: any) => {
    if (event.target.selectedIndex === 0) {
      setSelectedCustomer(null);
    } else {
      setSelectedCustomer(
        globalCtx.customerData[event.target.selectedIndex - 1]
      );
    }
  };

  const handleFormSubmit = async (event: any) => {
    globalCtx.setIsCreateNewOrderLoading(true);

    const newOrder: NewOrder = {
      customerId: selectedCustomer.id,
      document: enteredOrder,
    };

    const order = await globalCtx.createNewOrder(newOrder);

    if (order === null) {
      toast.error(
        `An error occurred. The Order was not uploaded for customer - ${selectedCustomer.name}.`
      );
    } else {
      toast.success(`Order uploaded for customer - ${selectedCustomer.name}.`);
    }

    event.preventDefault();
  };

  if (globalCtx.isCustomersDataLoading || globalCtx.isCreateNewOrderLoading) {
    return (
      <div className="App">
        <ThemeProvider>
          <div className="spinner">
            <Spinner size="large" />
          </div>
        </ThemeProvider>
      </div>
    );
  }

  return (
    <div className="App">
      <h1> Order Generator</h1>
      <div className="content-row">
        <div></div>
        <div className="content">
          <p>Environment: development</p>
        </div>
        <div className="content">
          <label htmlFor="customer">Customer</label>
          <br />
          <select name="customer" id="customer" onChange={handleSelectCustomer}>
            <option>select a customer</option>
            {customerOptions}
          </select>
        </div>
      </div>
      <div className="content-row">
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="order_hl7">Order HL7</label>
          <br />
          <textarea
            disabled={selectedCustomer === null}
            id="order_hl7"
            name="order_hl7"
            value={enteredOrder}
            onChange={orderChangeHandler}
            rows={15}
            cols={100}
          ></textarea>
          <br />
          <br />
          <button type="submit" disabled={selectedCustomer === null}>
            Upload Order
          </button>
        </form>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default Main;
