import './Navbar.css';

const Navbar = () => {
  const isOrderGeneratorEnabled =
    process.env.REACT_APP_ENABLE_ORDER_GENERATOR === 'true';

  return (
    <div className="navbar">
      {isOrderGeneratorEnabled && (
        <div className="navbar-link">
          <a href="/OrderGenerator">Order Generator</a>
        </div>
      )}
      <div className="navbar-link">
        <a href="/LegacyReportGenerator">Report Generator</a>
      </div>
    </div>
  );
};

export default Navbar;
