import { AppConfig } from '../appConfig';
import { AuthService } from './auth';
import axios from 'axios';

const createReportGeneratorService = (
  config: AppConfig,
  authService: AuthService
) => {
  const urlPrefix = '/api';
  const httpClient = axios.create({
    baseURL: config.LEGACY_REPORT_GENERATOR_API_HOST,
  });
  return {
    postTestReport: async (formData: FormData) => {
      const token = localStorage.getItem('access_token');
      let response = null;
      if (token) {
        try {
          response = await httpClient.post<Object>(
            `${urlPrefix}/manualOrder/`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
              },
            }
          );
          response = response.data;
        } catch (e) {
          if (e.response.status === 401) {
            authService.login(window.location.pathname);
          }
        }

        return response;
      }
    },
    postClintReport: async (formData: FormData) => {
      const token = localStorage.getItem('access_token');
      let response = null;

      if (token) {
        try {
          response = await httpClient.post<Object>(
            `${urlPrefix}/clintOrder/`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
              },
            }
          );
          response = response.data;
        } catch (e) {
          if (e.response.status === 401) {
            authService.login(window.location.pathname);
          }
        }

        return response;
      }
    },
    healthCheck: async () => {
      const token = localStorage.getItem('access_token');
      let response = null;

      if (token) {
        try {
          response = await httpClient.get<Object>(`${urlPrefix}/healthCheck/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
            },
          });
          response = response.data;
        } catch (e) {
          if (e.response.status === 401) {
            authService.login(window.location.pathname);
          }
        }

        return response;
      }
    },
  };
};

export type ReportGeneratorService = ReturnType<
  typeof createReportGeneratorService
>;
export default createReportGeneratorService;
