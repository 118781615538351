import { AppConfig } from '../appConfig';
import createOrderGeneratorService, {
  OrderGeneratorService,
} from './order-generator-service';
import createAuthService, { AuthService } from './auth';
import createReportGeneratorService, {
  ReportGeneratorService,
} from './report-generator-service';

const createServices = (config: AppConfig) => {
  const auth: AuthService = createAuthService(config);
  const orderGenService: OrderGeneratorService = createOrderGeneratorService(
    config,
    auth
  );
  const reportGenService: ReportGeneratorService = createReportGeneratorService(
    config,
    auth
  );

  return {
    orderGenService,
    reportGenService,
    auth,
  };
};

export type Services = ReturnType<typeof createServices>;
export default createServices;
