const appConfig = {
  APP_URL: process.env.REACT_APP_URL,
  ORDER_GENERATOR_API_HOST: process.env.REACT_APP_ORDER_GENERATOR_API_HOST,
  LEGACY_REPORT_GENERATOR_API_HOST:
    process.env.REACT_APP_LEGACY_REPORT_GENERATOR_API_HOST,
  COGNITO_LOGIN_URL: process.env.REACT_APP_COGNITO_LOGIN_URL,
  COGNITO_TOKEN_URL: process.env.REACT_APP_COGNITO_TOKEN_URL,
  COGNITO_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

export type AppConfig = typeof appConfig;
export default appConfig;
