import React, { useContext, useState } from 'react';
import { ServicesContext } from './services-context';
import Customer from '../models/Customer';
import Order from '../models/Order';
import NewOrder from '../models/NewOrder';

// Define global state object
type GlobalContextObj = {
  customerData: Customer[];
  fetchCustomersData: () => void;

  isCustomersDataLoading: boolean;
  setIsCustomersDataLoading: (isCustomersDataLoading: boolean) => void;

  errorLoadingCustomersData: boolean;
  setErrorLoadingCustomersData: (errorLoadingCustomersData: boolean) => void;

  createNewOrder: (newOrder: NewOrder) => Promise<Order>;

  isCreateNewOrderLoading: boolean;
  setIsCreateNewOrderLoading: (isCreateNewOrderLoading: boolean) => void;

  errorLoadingCreateNewOrder: boolean;
  setErrorLoadingCreateNewOrder: (errorLoadingCreateNewOrder: boolean) => void;

  reportGeneratorHealthCheck: () => void;

  createTestReport: (formData: FormData) => Promise<Object>;
  createClintReport: (formData: FormData) => Promise<Object>;

  isCreateReportLoading: boolean;
  setIsCreateReportLoading: (isCreateReportLoading: boolean) => void;

  errorLoadingCreateReport: boolean;
  setErrorLoadingCreateReport: (errorLoadingCreateReport: boolean) => void;
};

// Default context object, provided in case GlobalContextProvider is not rendered
export const GlobalContext = React.createContext<GlobalContextObj>({
  customerData: [],
  fetchCustomersData: () => {},

  isCustomersDataLoading: false,
  setIsCustomersDataLoading: (_) => {},

  errorLoadingCustomersData: false,
  setErrorLoadingCustomersData: (_) => {},

  createNewOrder: (NewOrder: NewOrder) => {
    return new Promise<Order>((resolve, reject) => {});
  },

  isCreateNewOrderLoading: false,
  setIsCreateNewOrderLoading: (_) => {},

  errorLoadingCreateNewOrder: false,
  setErrorLoadingCreateNewOrder: (_) => {},

  reportGeneratorHealthCheck: () => {},

  createTestReport: (formData: FormData) => {
    return new Promise<Object>((resolve, reject) => {});
  },
  createClintReport: (formData: FormData) => {
    return new Promise<Object>((resolve, reject) => {});
  },

  isCreateReportLoading: false,
  setIsCreateReportLoading: (_) => {},

  errorLoadingCreateReport: false,
  setErrorLoadingCreateReport: (_) => {},
});

const GlobalContextProvider: React.FC = (props) => {
  // Create the state variables to populate the default global context
  const [customerData, setCustomerData] = useState<Customer[]>(null);
  const [isCustomersDataLoading, setIsCustomersDataLoading] =
    useState<boolean>(false);
  const [errorLoadingCustomersData, setErrorLoadingCustomersData] =
    useState<boolean>(false);

  const [isCreateNewOrderLoading, setIsCreateNewOrderLoading] =
    useState<boolean>(false);
  const [errorLoadingCreateNewOrder, setErrorLoadingCreateNewOrder] =
    useState<boolean>(false);
  const [isCreateReportLoading, setIsCreateReportLoading] =
    useState<boolean>(false);
  const [errorLoadingCreateReport, setErrorLoadingCreateReport] =
    useState<boolean>(false);

  const servicesCtx = useContext(ServicesContext);
  const orderGenService = servicesCtx.services.orderGenService;
  const reportGenService = servicesCtx.services.reportGenService;

  const fetchCustomersData = async () => {
    let customers: Customer[] = [];
    if (customerData != null) {
      customers = customerData.slice();
    }
    setErrorLoadingCustomersData(false);
    setIsCustomersDataLoading(true);

    customers = await orderGenService.getCustomers();

    if (customers === null) {
      setErrorLoadingCustomersData(true);
    }

    if (customers.length === 0) {
      setCustomerData(null);
    } else {
      setCustomerData(customers.map((x) => x));
    }
    setIsCustomersDataLoading(false);
  };

  const createNewOrder = async (newOrder: NewOrder): Promise<Order> => {
    let order: Order = null;

    setErrorLoadingCreateNewOrder(false);
    setIsCreateNewOrderLoading(true);

    order = await orderGenService.postOrder(newOrder);

    if (order === null) {
      setErrorLoadingCreateNewOrder(true);
    }

    setIsCreateNewOrderLoading(false);

    return order;
  };

  const reportGeneratorHealthCheck = async () => {
    await reportGenService.healthCheck();
  };

  const createTestReport = async (formData: FormData): Promise<Object> => {
    let report;

    setErrorLoadingCreateReport(false);
    setIsCreateReportLoading(true);

    report = await reportGenService.postTestReport(formData);

    if (report === null) {
      setErrorLoadingCreateReport(true);
    }

    setIsCreateReportLoading(false);

    return report;
  };

  const createClintReport = async (formData: FormData): Promise<Object> => {
    let report;

    setErrorLoadingCreateReport(false);
    setIsCreateReportLoading(true);

    report = await reportGenService.postClintReport(formData);

    if (report === null) {
      setErrorLoadingCreateReport(true);
    }

    setIsCreateReportLoading(false);

    return report;
  };

  const contextValue: GlobalContextObj = {
    customerData,
    fetchCustomersData,

    isCustomersDataLoading,
    setIsCustomersDataLoading,

    errorLoadingCustomersData,
    setErrorLoadingCustomersData,

    createNewOrder,

    isCreateNewOrderLoading,
    setIsCreateNewOrderLoading,

    errorLoadingCreateNewOrder,
    setErrorLoadingCreateNewOrder,

    reportGeneratorHealthCheck,

    createTestReport,
    createClintReport,

    isCreateReportLoading,
    setIsCreateReportLoading,

    errorLoadingCreateReport,
    setErrorLoadingCreateReport,
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
